import React from 'react'
import { StaticQuery } from 'gatsby'
import _ from 'lodash'
import Layout from '../../components/layout'
import PageFooter from '../../components/PageFooter'
import Image from '../../components/Image'
import Timeline from '../../components/Timeline'
import Faqs from '../../components/Faqs'

import mapMarkdownRemarks from '../../components/mapMarkdownRemarks'
import { Row, Col } from 'react-flexbox-grid'

import withAdminLinks from '../../utils/withAdminLinks';

function Div(props) {
  return <div className={`section ${props.className}`} style={props.style}>
    {props.children}
  </div>
}

const ContentWrapper = withAdminLinks(Div);

const BookclubPage = ({ vision, moderator, language }) => {
  return (
    <Layout language={language}>
      <ContentWrapper node={vision} className="section" style={{}}>
        <div className="container">
          <Row center="xs">
            <Col xs={12} sm={9}>
              <div>
                <h1 className="text-left">{vision.title}</h1>
                <div
                  className="md-content"
                  dangerouslySetInnerHTML={{ __html: vision.html }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </ContentWrapper>
      {false && (
        <ContentWrapper node={moderator} className="section" style={{}}>
          <div className="container">
            <Row>
              <Col md="6" sm="12">
                <Image
                  height="300px"
                  width="100%"
                  mode="fill"
                  src={_.get(moderator.images, '[0]')}
                />
              </Col>
              <Col md="6" sm="12" style={{ padding: 0, alignSelf: 'center' }}>
                <Row>
                  <Col xs={12} style={{ paddingLeft: 30, paddingRight: 30 }}>
                    <h3 style={{}}>{moderator.title}</h3>
                    <div
                      className="md-content"
                      dangerouslySetInnerHTML={{ __html: moderator.html }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </ContentWrapper>
      )}
      <PageFooter language={language} />
    </Layout>
  )
}

const BookclubPageQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        vision: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#book-club-vision" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }

        moderator: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#book-club-moderator" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
      }
    `}
    render={data => {
      const language = _.get(props.pageContext, 'language')

      const vision = _.first(mapMarkdownRemarks(data.vision.edges || [], language))
      const moderator = _.first(mapMarkdownRemarks(data.moderator.edges || []), language)

      return (
        <BookclubPage language={language} vision={vision} moderator={moderator} {...props} />
      )
    }}
  />
)

export default BookclubPageQuery
