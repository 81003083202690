import React from 'react'
import _ from 'lodash'
import { Row, Col } from 'react-flexbox-grid'

import expandIcon from '../images/expand-button.png'
import Image from './Image'

class FaqEntry extends React.Component {
  constructor(props) {
    super(props)
    this.answerRef = React.createRef()
  }

  componentDidMount() {
    this.answerRef.current.style.display = 'none'
  }

  toggle() {
    const currentDisplay = this.answerRef.current.style.display
    if (currentDisplay === 'none') {
      this.answerRef.current.style.display = ''
    } else {
      this.answerRef.current.style.display = 'none'
    }
  }

  render() {
    const { question, answer, expanded } = this.props
    return (
      <Row style={{ paddingTop: 0 }}>
        <Col xs={12} style={{ position: 'relative', alignContent: 'stretch' }}>
          <Row
            onClick={() => this.toggle()}
            role="button"
            start="xs"
            style={{ marginBottom: '1em' }}
          >
            <Col
              xs={12}
              style={{
                borderBottomColor: 'hsla(0,0%,0%,0.9)',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                position: 'relative',
              }}
            >
              <h4 style={{ marginTop: '1em', marginBottom: '1em' }}>
                {question}
              </h4>
              <Image
                className="Faq-line"
                src={expandIcon}
                style={{
                  height: '20px',
                  width: '20px',
                  position: 'absolute',
                  top: '50%',
                  bottom: '50%',
                  right: '0',
                  backgroundColor: 'transparent',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ paddingLeft: '8' }}>
              <div
                ref={this.answerRef}
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

function Faq({ entries }) {
  return (
    <Row start="xs">
      {entries.map((entry, index) => (
        <Col key={`faq-entry-${index}`} xs={12}>
          <FaqEntry question={entry.question} answer={entry.answer} />
        </Col>
      ))}
    </Row>
  )
}

export default Faq
