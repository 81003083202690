import React from 'react'

function Links({ visible, slug }) {
  return <a className="cms-links hidden" href={`/admin/#/collections/content-block/entries/${slug.substr(1, slug.length - 2)}`}>
    <img height="20px" src="https://c7.uihere.com/icons/287/887/691/edit-pen-icon-19d623e198a3d220609d4caab0b25ae3.png"></img>
  </a>
}

export default function withAdminLinks(WrappedComponent) {
  return class AdminLinksComponent extends React.Component {
    constructor() {
      super();
    }

    render() {
      const { node } = this.props

      const slug = node ? node.slug : this.props.slug

      if (slug) {
        return (
          <div className="with-admin-links" style={{ position: 'relative' }}>
            <Links slug={slug} />
            <WrappedComponent {...this.props} />
          </div>
        )
      } else {
        return (
          <React.Fragment>
            <WrappedComponent {...this.props} />
          </React.Fragment>
        )
      }
    }
  }
}
