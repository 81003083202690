import React from 'react'
import _ from 'lodash'
import { Row, Col } from 'react-flexbox-grid'
import withAdminLinks from '../utils/withAdminLinks'

function TimelineEntry({ title, text, lastEntry, index }) {
  return (
    <Col xs={12} style={{ minHeight: 100 }}>
      <Row start="xs" style={{ paddingTop: 32 }}>
        <Col
          xs={2}
          className="hidden-xs"
          style={{
            position: 'relative',
            alignContent: 'stretch',
            maxWidth: 80,
          }}
        >
          {!lastEntry && (
            <div
              className="timeline-line"
              style={{
                backgroundColor: '#83ba09',
                width: 1,
                height: '80px',
                position: 'absolute',
                top: 29,
                bottom: 0,
                left: '29px',
                marginTop: 32,
              }}
            />
          )}
          <Row
            className="flex-center__y"
            center="xs"
            style={{
              height: 60,
              width: 60,
              backgroundColor: '#83ba09',
              borderRadius: '50%',
            }}
          >
            <span
              style={{ color: 'white', fontSize: '1.5em' }}
            >{`${index}`}</span>
          </Row>
        </Col>
        <Col xs={10} style={{ paddingLeft: 32 }}>
          <h4 style={{ marginTop: 0, marginBottom: 0 }}>{title}</h4>
          <span dangerouslySetInnerHTML={{__html: text}}/>
        </Col>
      </Row>
    </Col>
  )
}

function Timeline({ steps }) {
  return (
    <Row start="xs">
      {_.map(steps, (step, index) => (
        <TimelineEntry
          index={index}
          key={`${index}`}
          lastEntry={index === _.size(steps) - 1}
          title={step.title}
          text={step.shortExcerpt}
        />
      ))}
    </Row>
  )
}

export default withAdminLinks(Timeline)
